'use client';

import { useTransition } from 'react';

import { useScopedI18n } from '@/lib/i18n/client';
import { Loader2, RotateCcw } from 'lucide-react';
import Link from 'next/link';
import { useRouter } from 'next/navigation';

import { Button } from '@/components/ui/button';

export default function Error({ reset }: { reset: () => void }) {
  const t = useScopedI18n('error');
  const [isPending, startTransition] = useTransition();
  const router = useRouter();

  return (
    <main className='flex min-h-[calc(100dvh-290px)] flex-col items-center justify-center'>
      <section className='mx-auto max-w-7xl space-y-6 px-4 py-32'>
        <div className='space-y-2 text-center'>
          <span className='font-semibold uppercase text-gray-500'>500</span>
          <h1 className='text-balance text-3xl font-bold tracking-tight'>
            {t('title')}
          </h1>
          <p className='text-balance text-gray-500'>{t('description')}</p>
        </div>

        <div className='flex flex-col items-center justify-center gap-2 md:flex-row'>
          <Button
            variant='secondary'
            disabled={isPending}
            onClick={() => {
              startTransition(() => {
                router.refresh();
                reset();
              });
            }}
          >
            {isPending ? (
              <Loader2 className='mr-2 animate-spin' />
            ) : (
              <RotateCcw className='mr-2' />
            )}

            {t('reload')}
          </Button>

          <Button variant='ghost' asChild>
            <Link href='/'>{t('take-me-home')}</Link>
          </Button>
        </div>
      </section>
    </main>
  );
}
